.App {
    // background-color: rgba(55, 65, 81, 1);
    background-color: rgb(44, 52, 65);
    color: rgba(229, 231, 235, 1);

    &.dark {
        background-color: rgb(44, 52, 65);
        color: rgba(229, 231, 235, 1);
    }

    &.light {
        background-color: white;
        color: black
    }

    &-body {
        font-family: 'Ubuntu';
        max-width: 85ch;
        margin: auto;
        line-height: 1.6;
        padding-top: 96px;
        padding-left: 16px;
        padding-right: 16px;

        @media screen and (max-width: 511px) {
            font-size: 1rem;
        }
    }

    &-sectionHeader {
        margin: 1.75em 0 0 0;
    }
}
