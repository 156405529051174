$light-blue: #AED9E0;
$beige: #FAF3DD;
$grey: #5E6472;
$black: #000000;
$yellow: #f5b81f;
$pink: #ffcee5;

.penguinContainer {
    position: fixed;
    width: 600px;
    height: 420px;
    top: -175px;
    transform: scale(0.1);
    @media screen and (max-width: 511px) {
        left: -56px;
    }
}

.penguin {
    background: $grey;
    width: 30%;
    height: 50%;
    border-radius: 50% 50% 70px 70px;
    transform: scale(2);

    &-body {
        position: absolute;
        top: 40%;
        left: 12.5%;
        width: 75%;
        height: 50%;
        border-radius: 50% 50% 50px 50px;
        background: $beige;
    }

    &-mouth {
        background: $yellow;
        position: absolute;
        top: 15%;
        left: 38.5%;
        width: 23%;
        height: 18%;
        border-radius: 45%;
        -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        z-index: 2;
    }

    &-cheek {
        &-left {
            position: absolute;
            background: $pink;
            width: 17px;
            height: 10px;
            border-radius: 50%;
            top: 30%;
            z-index: 3;
            left: 10%;
        }

        &-right {
            position: absolute;
            background: $pink;
            width: 17px;
            height: 10px;
            border-radius: 50%;
            top: 30%;
            z-index: 3;
            right: 10%;
        }
    }

    &-feet {
        width: 40px;
        height: 80px;
        position: absolute;
        background: $yellow;
        border-radius: 50%;
        bottom: -12%;
        z-index: -1;

        &-left {
            left: 17%;
            -webkit-transform: rotate(75deg);
            transform: rotate(75deg);
        }

        &-right {
            -webkit-transform: rotate(-75deg);
            transform: rotate(-75deg);
            right: 17%;
        }
    }

    &-arm {
        &-left {
            width: 35px;
            height: 90px;
            position: absolute;
            background: $grey;
            border-radius: 50%;
            top: 30%;
            left: -10%;
            -webkit-transform: rotate(-150deg);
            z-index: -1;

            transform: rotate(130deg);
            animation-name: wave;
            animation-duration: 0.6s;
            animation-iteration-count: infinite;
            

            @keyframes wave {
                10% {
                    transform: rotate(90deg);
                }

                20% {
                    transform: rotate(130deg);
                }

                30% {
                    transform: rotate(90deg);
                }

                40% {
                    transform: rotate(130deg);
                }
            }
        }

        &-right {
            width: 35px;
            height: 90px;
            position: absolute;
            background: $grey;
            border-radius: 50%;
            top: 30%;
            right: -10%;
            -webkit-transform: rotate(150deg);
            z-index: -1;

            transform: rotate(50deg);
            animation-duration: 0.6s;
            animation-name: wave2;
            animation-iteration-count: infinite;


            @keyframes wave2 {
                10% {
                    transform: rotate(90deg);
                }

                20% {
                    transform: rotate(50deg);
                }

                30% {
                    transform: rotate(90deg);
                }

                40% {
                    transform: rotate(50deg);
                }
            }
        }
    }

    &-eye {
        position: absolute;
        background: #000000;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        top: 26%;
        z-index: 4;

        &-left {
            left: 23%;
        }

        &-right {
            right: 23%;
        }

        &-pupil {
            position: absolute;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            left: 23%;
            top: 15%;
            background: #ffffff;
        }
    }

    .body-top-left {
        background: $beige;
        border-radius: 60% 30% 0% 0%;
        position: absolute;
        top: 17%;
        left: 12.5%;
        width: 38.5%;
        height: 50%;
    }

    .body-top-right {
        background: $beige;
        border-radius: 30% 60% 0% 0%;
        position: absolute;
        top: 17%;
        right: 12.5%;
        width: 38.5%;
        height: 50%;
    }
}
