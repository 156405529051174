.aboutMe {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: 700;

        &-left {
            &-socials {
                display: flex;

                &IconContainer {
                    height: 2.25rem;
                    margin-bottom: .25rem;
                    margin-right: .25rem;
                    padding-left: .75rem;
                    padding-right: .75rem;
                    border-radius: .25rem;
                    display: flex;
                    align-items: center;
                    background-color: #f9fafb1a;
                    cursor: pointer;
                }

                &IconContainer:hover {
                    background-color: rgba(107, 114, 128, 1) !important;
                }

                &Icon {
                    width: 1.25rem;
                    cursor: pointer;
                }
            }
        }

        &-right {
            display: flex;
            width: 6rem !important;
            border-radius: .375rem;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(75, 85, 99, 1);
            padding: .25rem;


            &-image {
                width: 100%;
                border-radius: 0.25rem;
                -webkit-transition: all 0.5s ease-in-out;
                -webkit-perspective: 600px;


                &:hover {
                    -webkit-filter: grayscale(1);
                    -webkit-transform: scale(-1, 1);
                }
            }
        }
    }

    &-description {

        &.dark {
            a {
                text-decoration: none;
                color: rgba(147, 197, 253, 1);
            }
        }

        &.light {
            a {
                text-decoration: none;
                color: rgba(23, 114, 208, 1);
            }
        }
    }
}