.education {

    &-title {
        font-size: 1.75rem;
    }

    &-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1.5rem;

        &-degreeName {
            margin: 0;
        }
    }

    &-headerContainer {
        display: flex;
        justify-content: space-between;

        &-role {
            font-size: 1.5rem;
            margin: 0;
        }

        &-companyName {
            font-size: 1.2rem;
        }

    }
}