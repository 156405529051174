.experience {

    &-title {
        font-size: 1.75rem;
    }

    &-headerContainer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 1.5rem;

        &-role {
            font-size: 1.5rem;
            margin: 0;
        }

        &-prof {
            font-size: 17px;
            display: inline;
        }

        &-companyName {
            font-size: 1.2rem;
            margin: 0;
            border-bottom: 2px solid #ad808e;
            width: -moz-fit-content;
            width: fit-content;
            text-decoration: none;
            color: inherit;
        }

        &-duration {
            text-align: end;
        }

        a {
        }
    }
}
