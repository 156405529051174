.header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 56px;
    width: 100%;
    z-index: 2;
    background-color: inherit;
    font-size: 18px;
    color: inherit  ;
    border-bottom-width: 1px;
    border-bottom-color: rgba(75,85,99,1);
    border-bottom-style: solid;

    &-icon {
        width: 1.50rem;
        cursor: pointer;
    }

    &-left {
        position: absolute;
        left: 15px;
        top: 13px;
        margin: 0;

        &-underscore {
            animation-name: blink;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            font-weight: 700;
        }

        @keyframes blink {
            0% {
                color: rgb(44, 52, 65);
                ;
            }

            25% {
                color: rgb(229, 231, 235);
            }

            50% {
                color: rgb(44, 52, 65);
                ;
            }

            75% {
                color: rgb(229, 231, 235);
            }

            100% {
                color: rgb(44, 52, 65);
                ;
            }
        }
    }

    &-right {
        display: flex;
        gap: 14px;
        align-items: flex-end;
        right: 15px;
        position: absolute;
        top: 13px;

        a {
            color: inherit;
            cursor: pointer;
        }
    }

}
